
.buy-tickets-button {
    background-color: #0D1F34;
    font-size: 15px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    display: inline-block;
    margin: auto;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    color: #FFFFFF;
    letter-spacing: .07em;
    padding: 18px 27px;
    font-family: arial,sans-serif !important;

    &:hover {
        color: #FFFFFF;
        text-decoration: underline;
    }
}