html {
  font-family: sans-serif;
}
  
body {
  background: #eee;
  color: #666;
  margin-top: 100px;
}

header {
  
  & nav.navbar {
    
    background: #0D1F34;
    box-shadow: 0 0 10px #888;

    
  }
  
}

.page-container {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 600px;
}

a.anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}


h1 {
  display: none;
}
h2 {
  text-transform: uppercase;
}

h1, h2 {
  text-align: center;
  margin: 0;
  padding: 10px 0;
}

footer {
  background: #0D1F34;
  color: #eee;
  font-size: .8em;
  padding: 10px;
  text-align: center;
}

#tagline {
  display: none;
}
