

#carousel {
  overflow: hidden;
  height: 300px;
  border-bottom: 2px solid #666;
}

#section-upcoming-shows {

  background: #ddd;
  padding-bottom: 30px;
  padding-top: 30px;

  li {
    padding-bottom: 20px;
  }
}

